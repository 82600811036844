import { Box, Paper, styled, Typography } from "@mui/material";

export const NewsletterContainer = styled(Box)`
  padding: 64px 0;

  @media (min-width: 900px) {
    padding: 80px 0;
  }
`;

export const NewsletterCard = styled(Paper)`
  padding-bottom: 80px;
  border-radius: 24px;
  overflow: hidden;
`;

export const MainTitle = styled(Typography)`
  font-size: 2.5rem;
  @media (min-width: 900px) {
    font-size: 3.625rem;
  }
`;

export const ImageContainer = styled(Box)`
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
  }
`;

export const NewsletterContent = styled(Box)`
  margin: 24px auto 0;
  padding: 0 24px;
  max-width: 560px;
  text-align: center;
`;

import { Container, Typography, useScrollTrigger } from "@mui/material";
import React from "react";
import { AdaptiveToolbar, MainAppBar } from "./Header.styles";

const Header = ({ window }) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <MainAppBar>
      <AdaptiveToolbar expanded={!trigger ? 1 : 0} disableGutters>
        <Container>
          <Typography variant='h6' component='div'>
            BEzGG
          </Typography>
        </Container>
      </AdaptiveToolbar>
    </MainAppBar>
  );
};

export default Header;

import { Typography } from "@mui/material";
import React from "react";
import { FooterContainer } from "./Footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <Typography variant='h4' as='p'>
        BEzGG
      </Typography>
      <Typography variant='caption'>© 2022. All rights reserved</Typography>
    </FooterContainer>
  );
};

export default Footer;

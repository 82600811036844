import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  InnerContainer,
  LeftPanel,
  MainSectionContainer,
  MainTitle,
  RightImagePanel,
} from "./MainSection.styles";

const MainSection = () => {
  return (
    <MainSectionContainer>
      <InnerContainer>
        <Grid height='100%' container spacing={3} alignItems='center'>
          <Grid item xs={12} md={5}>
            <LeftPanel>
              <MainTitle variant='h1' component='h1'>
                We build <br /> Easy Great Games
              </MainTitle>
              <Typography variant='body1'>
                We are young game developers with a lot of passion about creating easy to play great gaming experiences.
              </Typography>
            </LeftPanel>
          </Grid>
          <Grid item xs={12} md={7}></Grid>
        </Grid>
      </InnerContainer>
      <RightImagePanel>
        <img
          alt='We build easy great games'
          src='https://images.pexels.com/photos/194511/pexels-photo-194511.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        />
      </RightImagePanel>
    </MainSectionContainer>
  );
};

export default MainSection;

import { Container, Link, Typography } from "@mui/material";
import React from "react";
import {
  ImageContainer,
  MainTitle,
  NewsletterCard,
  NewsletterContainer,
  NewsletterContent,
} from "./Newsletter.styles";

const Newsletter = () => {
  return (
    <NewsletterContainer>
      <Container>
        <NewsletterCard>
          <ImageContainer>
            <img
              alt="Newsletter"
              src="https://images.pexels.com/photos/7915276/pexels-photo-7915276.jpeg?cs=srgb&dl=pexels-rodnae-productions-7915276.jpg&fm=jpg"
            />
          </ImageContainer>
          <NewsletterContent>
            <MainTitle variant="h2" component="h2">
              Keep in touch
            </MainTitle>
            <Typography variant="body2">
              Send us an email at{" "}
              <Link href="mailto:developer@ecozify.com">
                developer@ecozify.com
              </Link>
            </Typography>
          </NewsletterContent>
        </NewsletterCard>
      </Container>
    </NewsletterContainer>
  );
};

export default Newsletter;

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Bezgg from "./pages/Bezgg/Bezgg";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111111",
    },
  },
  typography: {
    fontFamily: "'Odibee Sans', cursive;",
    fontSize: 20,
    h1: {
      fontFamily: "'Koulen', cursive;",
    },
    h2: {
      fontFamily: "'Koulen', cursive;",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route path='/bezgg'>
            <Bezgg />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

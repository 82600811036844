import { AppBar, styled, Toolbar } from "@mui/material";

export const MainAppBar = styled(AppBar)`
  background-image: none;
  box-shadow: none;
  background-color: transparent;
`;

export const AdaptiveToolbar = styled(Toolbar)`
  background-color: ${(props) =>
    props.expanded ? "rgb(45, 45, 45)" : "rgb(45, 45, 45, 0.8)"};
  transition: height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  @media (min-width: 900px) {
    height: ${(props) => (props.expanded ? "96px" : "76px")};
  }
`;

import { Box, Container, styled, Typography } from "@mui/material";

export const MainSectionContainer = styled(Box)`
  position: relative;
  overflow: hidden;

  @media (min-width: 900px) {
    height: 100vh;
  }
`;

export const InnerContainer = styled(Container)`
  height: 100%;
`;

export const LeftPanel = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;

  @media (min-width: 0px) {
    align-items: center;
    text-align: center;
  }
  @media (min-width: 900px) {
    align-items: flex-start;
    text-align: left;
  }
`;

export const MainTitle = styled(Typography)`
  font-size: 2.5rem;
  @media (min-width: 900px) {
    font-size: 3.625rem;
  }
`;

export const RightImagePanel = styled(Box)`
  max-width: 1280px;
  position: absolute;

  @media (min-width: 0px) {
    display: none;
  }
  @media (min-width: 900px) {
    bottom: 20%;
    right: -110px;
    display: block;
    width: 58%;
  }
  @media (min-width: 1200px) {
    bottom: 40px;
  }

  @media (min-width: 1536px) {
    right: 0px;
    width: 45%;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
  }
`;

import { Box } from "@mui/material";
import React from "react";
import MainSection from "./MainSection/MainSection";
import Newsletter from "./Newsletter/Newsletter";

const Homepage = () => {
  return (
    <Box>
      <MainSection />
      <Newsletter />
    </Box>
  );
};

export default Homepage;

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Homepage from "./Homepage/Homepage";
import Policy from "./MatchOfHorrors/Policy/Policy";

const Bezgg = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Header />
      <Switch>
        <Route exact path={`${path}/`}>
          <Homepage />
        </Route>
        <Route path={`${path}/matchofhorrors/policy`}>
          <Policy />
        </Route>
      </Switch>
      <Footer />
    </>
  );
};

export default Bezgg;
